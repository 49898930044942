<template>
  <div>
    <div v-if="isAnimation && !noWmtsFeaturesFound">
      <strong>{{ $t('ServiceCard.animation') }}</strong>
      <v-row class="ma-0">
        <v-col
          cols="12"
          md="4"
          class="mt-2 pl-0"
          style="min-height: 70px"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <b
                class="RMprimary-color"
                v-bind="attrs"
                v-on="on"
              >
                {{ dailyData ? animationCurrentStep.split(" ")[0] : animationCurrentStep }}
              </b>
            </template>
            <span>{{ $t('ServiceCard.localTime') }}</span>
          </v-tooltip>
        </v-col>
        <v-col
          v-for="player of playerIcons"
          :key="player"
          cols="3"
          md="2"
          class="mobile-margin"
        >
          <v-btn
            v-blur
            fab
            depressed
            small
            :disabled="playerIconsDisabled(player)"
            @click="changeAnimationStep(player)"
          >
            <v-icon dark>
              {{ player }}
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-if="!isAnimation">
      {{ $t('ServiceCard.lastUpdateDate') }} <b class="RMprimary-color">{{ dailyData ? animationCurrentStep.split(' ')[0] : animationCurrentStep }}</b>
    </div>
    <v-alert
      :value="noFeaturesFound || noWmtsFeaturesFound"
      dense
      dismissible
      type="warning"
      icon="mdi-alert-circle-outline"
      class="mt-2 mb-0"
      transition="scale-transition"
    >
      {{ noWmtsFeaturesFound ? $t('ServiceCard.noWmtsFeaturesFound') : $t('ServiceCard.errorAlert') }}
    </v-alert>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getFormattedCurrentDate } from '@/utils.js';

export default {
  props: {
    serviceCardId: {
      type: String,
      default: ''
    },
    animationStepDateType: {
      type: String,
      default: ''
    },
    animationStep: {
      type: String,
      default: ''
    },
    layerType: {
      type: String,
      default: ''
    },
    serviceName: {
      type: String,
      default: ''
    },
    isAnimation: {
      type: Boolean,
      default: false
    },
    overlay: {
      type: Boolean,
      default: false
    },
    minStep: {
      type: Date,
      default: null
    },
    maxStep: {
      type: Date,
      default: null
    },
    selectedRasterLayer: {
      type: Object,
      default: null
    },
    selectedVectorLayer: {
      type: Object,
      default: null
    },
    animationLimitRangeArray: {
      type: Array,
      default: () => []
    },
    animationLimitRangeArrayPolicy: {
      type: Array,
      default: () => []
    },
    animationTimeArray: {
      type: Array,
      default: () => []
    },
    animationEmptySteps: {
      type: Array,
      default: () => []
    },
    animationStartIndex: {
      type: Number,
      default: 0
    },
    noFeaturesFound: {
      type: Boolean,
      default: false
    },
    noWmtsFeaturesFound: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      idleToFuture: false,
      idleToPast: false,
      isPlaying: false,
      playerIcons: ['mdi-skip-backward', 'mdi-play', 'mdi-skip-forward'],
      index: this.animationStartIndex,
      rasterTimeStep: ''
    };
  },
  computed: {
    ...mapState(['map', 'policy', 'app_user', 'geoserver_data']),
    checkForActivatedLayers() {
      if (this.map.disableAnimationPlay) {
        return true;
      } else {
        return this.selectedVectorLayer !== null || this.selectedRasterLayer !== null;
      }
    },
    dailyData() {
      return this.animationStepDateType === 'dynamic_months' || this.animationStepDateType === 'dynamic_days' || (this.animationStepDateType === 'dynamic_hours' && this.animationStep >= 24);
    },
    animationCurrentStep() {
      if (this.animationTimeArray.length > 0 && this.index !== -1) {
        if (this.layerType === 'VECTOR') {
          const index = this.$route.params.id === 'Forecast' ? 0 : 1;

          return this.getFormattedCurrentDateLocal(new Date(this.animationTimeArray[this.index].split('/')[index]));
        } else {
          return this.rasterTimeStep;
        }
      } else {
        return '';
      }
    }
  },
  watch: {
    $route() {},
    animationStartIndex: {
      async handler(newValue) {
        if (newValue) {
          if (!this.map.isAnimating.includes(this.serviceCardId)) {
            this.index = this.animationStartIndex;
          }
        }
      }
    },
    animationTimeArray: {
      async handler(newValue) {
        if (newValue) {
          this.rasterTimeStep = this.getFormattedCurrentDateLocal(new Date(this.animationTimeArray[this.index]));

          if (this.$route.params.id === 'Forecast' && !this.map.isAnimating.includes(this.serviceCardId)) {
            this.index = this.animationStartIndex;
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions(['setRasterLayerAnimationTime', 'loadVectorLayer', 'removePreviousVectorLayer', 'resetAnimationTime']),
    playerIconsDisabled(player) {
      if (this.animationTimeArray.length === 0) {
        return true;
      } else {
        return this.map.meteogramActive || (this.map.disableAnimationPlay && player === 'mdi-play') ||
              (this.map.disableAnimationPlay && player !== 'mdi-pause' && this.isPlaying) ||
              (!this.map.disableAnimationPlay && this.idleToFuture && player === 'mdi-skip-forward') ||
              (!this.map.disableAnimationPlay && this.idleToPast && player === 'mdi-skip-backward');
      }
    },
    getFormattedCurrentDateLocal(date) {
      return getFormattedCurrentDate(date);
    },
    async changeAnimationStep(player) {
      if (player === 'mdi-play') {
        if (this.layerType === 'RASTER') {
          this.map.isAnimating.push(this.selectedRasterLayer.service_id);
          this.map.animationInterval = window.setInterval(
            this.stepNext
            , 1000);
        } else {
          this.map.isAnimating.push(this.selectedVectorLayer.service_id);
          this.map.animationInterval = window.setInterval(
            this.stepNext
            , 1000);
        }

        this.isPlaying = true;
        this.playerIcons[1] = 'mdi-pause';
        this.map.disableAnimationPlay = true;
      }

      if (player === 'mdi-skip-forward') {
        await this.stepNext();
      }

      if (player === 'mdi-skip-backward') {
        await this.stepPrev();
      }

      if (player === 'mdi-pause') {
        this.isPlaying = false;
        this.playerIcons[1] = 'mdi-play';
        await this.resetAnimationTime();
      }
    },
    async stepNext() {
      this.idleToFuture = true;

      if (this.index < (this.animationTimeArray.length - 1)) {
        ++this.index;
      } else {
        this.index = 0;
      }

      await this.loadLayer();
      setTimeout(() => {
        this.idleToFuture = false;
      }, 100);
    },
    async stepPrev() {
      this.idleToPast = true;

      if (this.index > 0) {
        --this.index;
      } else {
        this.index = this.animationTimeArray.length - 1;
      }

      await this.loadLayer();
      setTimeout(() => {
        this.idleToPast = false;
      }, 100);
    },
    async loadLayer() {
      if (this.layerType === 'RASTER') {
        this.$emit('no-raster-features-found', this.animationEmptySteps.includes(this.animationTimeArray[this.index]));
        const data = {
          updatedDate: new Date(this.animationTimeArray[this.index]),
          service_id: this.serviceCardId,
          layerType: this.layerType,
          extent: this.geoserver_data.geographicDomains[this.serviceCardId]
        };
        this.map.rasterMarkerDate = data.updatedDate;
        await this.setRasterLayerAnimationTime(data);
        // this.$gtag.event('Intervallo temporale', {
        //   event_category: `${this.$route.params.id == undefined ? 'Home' : this.$route.params.id} - ${this.serviceName}`,
        //   event_label: `${Date.now()} - ${this.app_user.username} - ${this.app_user.crm_id} - ${getFormattedDate(new Date(), true, true)}`,
        //   value: `${getFormattedDate(new Date(data.updatedDate), true)}`
        // });
      } else {
        this.$emit('no-vector-features-found', this.animationEmptySteps.includes(this.animationTimeArray[this.index]));
        await this.openVectorLayer();
      }

      if (this.map.disableAnimationPlay) {
        setTimeout(() => (this.rasterTimeStep = this.getFormattedCurrentDateLocal(new Date(this.animationTimeArray[this.index]))), 1000);
      } else {
        this.rasterTimeStep = this.getFormattedCurrentDateLocal(new Date(this.animationTimeArray[this.index]));
      }
    },
    async openVectorLayer() {
      this.map.sliderOpacityValue = 60;

      const vectorParams = {
        params: {
          GROUP: this.$route.params.id,
          LAYERS: `${this.selectedVectorLayer.ws_name}:${this.selectedVectorLayer.layer_name}`,
          TIME: this.animationTimeArray[this.index],
          STYLES: this.selectedVectorLayer.layer_style,
          USERNAME: this.app_user.username,
          SERVICE_NAME: this.serviceName,
          PARAM_RANGE: this.map.vectorParamRange[this.serviceCardId]
        },
        TYPE: 'VECTOR',
        LAYER_SERVICE_ID: this.serviceCardId,
        EXTENT: this.geoserver_data.geographicDomains[this.serviceCardId]
      };

      const layerFound = this.map.OLmap.getLayers().getArray().slice(1).some(selectedLayer => selectedLayer.values_.time === vectorParams.params.TIME && (selectedLayer.values_.layer_name === vectorParams.params.LAYERS && selectedLayer.values_.service_id === vectorParams.LAYER_SERVICE_ID));

      if (!layerFound) {
        await this.loadVectorLayer(vectorParams);
      }

      // this.$gtag.event('Intervallo temporale', {
      //   event_category: `${this.$route.params.id == undefined ? 'Home' : this.$route.params.id} - ${this.serviceName}`,
      //   event_label: `${Date.now()} - ${this.app_user.username} - ${this.app_user.crm_id} - ${getFormattedDate(new Date(), true, true)}`,
      //   value: `${getFormattedDate(new Date(vectorParams.params.TIME.split("/")[0]), true)} - ${getFormattedDate(new Date(vectorParams.params.TIME.split("/")[1]), true)}`
      // });
    }
  }
};
</script>

<style scoped>
.RMprimary-color {
  color: #00A644;
}
@media (min-device-width:280px) and (max-device-width:767px){
  .mobile-margin {
   padding-left: 24px !important;
  }
}
</style>
